.user {
    flex: 4;
    background-color: #fafafb;
    padding: 20px;
}

.userTitleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userAddButton {
    min-width: 80px;
    border: none;
    padding: 10px;
    background-color: var(--menuActiveFontColor);
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;

        &:hover {
            background-color: var(--mainColor);
        }
}

.userContainer {
    display: flex;
    box-shadow: 0 0px 5px -1px rgb(0 0 0 / 0.20);
    border-radius: 5px;
}

.userControlsList {
    flex: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    padding: 20px 0px;
    margin: 0;
}

.userUpdate {
    flex: 2;
    padding: 20px;
}

.userUpdateForm {
    // display: flex;
    // flex-direction: column;
    // margin: 20px 0 0 20px;
}

.userUpdateFormWrap {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
}

.userUpdateFormRight  {
    margin-left: 20px;
}

.userUpdateFormItem {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
        label {
            margin-bottom: 5px;
            font-weight: 600;
        }

        .userUpdateFormInput {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 15px;
            padding: 10px 15px 10px 15px;
            min-width: 200px;
        }
}

.userUpdateAvatar {
    margin-right: 10px;
}

.userSubmitButton {
    margin-top: 20px;
}