:root {
  --mainColor: #041241;
  --menuActiveFontColor: #3877EF;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: var(--mainColor);
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
